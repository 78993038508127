import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  //GENIUS

  // genius service point
  {
    path: 'genius-service-point',
    loadChildren: () =>
      import(
        './components/new_pages/genius_service_point/genius-service-point.module'
      ).then((m) => m.GeniusServicePointModule),
    data: { breadcrumb: 'GeniusServicePoint' },
  },
  // partner home
  {
    path: 'partner-home',
    loadChildren: () =>
      import('./components/new_pages/partner_home/partner-home.module').then(
        (m) => m.PartnerHomeModule
      ),
    data: { breadcrumb: 'PartnerHome' },
  },
  // Home
  {
    path: '',
    loadChildren: () =>
      import('./components/new_pages/privati/privati.module').then(
        (m) => m.HomeModule
      ),
    data: { breadcrumb: 'Privati' },
  },
  {
    path: 'mappa',
    loadChildren: () =>
      import('./components/new_pages/mappa/mappa.module').then(
        (m) => m.MappaModule
      ),
    data: { breadcrumb: 'Mappa' },
  },
  {
    path: 'phygital',
    loadChildren: () =>
      import('./components/new_pages/phygital/phygital.module').then(
        (m) => m.PhygitalModule
      ),
    data: { breadcrumb: 'Phygital' },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      scrollOffset: [0, 50],
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
